import styles from "./styles.module.scss";

import { useRef, useState } from "react";
import Container from "../../components/Container/Container";
import { Link } from "react-router-dom";
import arrowDown from "../../assets/images/Icons/arrowDown.svg";
import logoAW from "../../assets/images/Icons/logoAW.svg";
import logoTQ from "../../assets/images/Icons/logoTQ.png";
import play from "../../assets/images/Icons/play.svg";
import characters from "../../assets/images/homepage/characters.png";
import characters2 from "../../assets/images/homepage/characters2.png";
import screenshots from "../../assets/images/homepage/screenshots.png";
import trailer from "../../assets/videos/trailer.mp4";

// LandingPage component
export const LandingPage = () => {
  const videoRef = useRef(null);
  const trailerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  const handleGetStartedClick = () => {
    window.scrollTo(0, 0);
  };

  const handleArrowClick = () => {
    trailerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  return (
    // Using the Container component for layout consistency
    <Container>
      <div className={styles.container}>
        {/* Title of the game */}
        <h2>Lucky Sevens</h2>

        {/* Container for the embedded iframe and direct link */}
        <div>
          <p>Welcome to Lucky Sevens! The exciting new place for mini games powered by the WAX black chain!</p>
          <p>Right now we only have one game: <a href="/dice/Dice.html">Dice</a>, but more are coming soon!</p>
        </div>
      </div>
    </Container>
  );
};
